
//--Collapse opened navbar after clicked on a navlink (relevant on medium or small size)
const navLinkClick = (e) => {
  try {
    let btn = document.getElementById('toggleNavBtn');
    if (btn.classList.contains('collapsed') === false) {
      btn.click();
    }
  }
  catch (err) {
    // console.log(err);
  }
}

//--Highlight the active link onlick
function highlightActiveNavLink(_id) {
  try {
    const links = document.querySelectorAll('.nav_link');
    links.forEach(ltx => ltx.classList.remove('active_link'));

    const link = document.getElementById(_id + 'Link');
    link.classList.add('active_link');
  }
  catch (err) {
    // console.log(err);
  }
}

//--Calculate navbar offset (where to scroll after clicked on a navlink)
function scrollOffset(_e, _location, _navHeight, _id) {
  try {
    if (document !== null) {
      let yOffset;
      let y;

      if (document.querySelector('.navbar1').classList.contains('navbar_sticky') === true) {
        yOffset = -_navHeight;
      }
      else {
        yOffset = -_navHeight * 2;
      }
      //                 
      // if (_location.pathname.includes('galeria') && _location.hash.includes('#kapcsolat')) {
      //   window.scrollTo({ top: yOffset, behavior: 'smooth' });     
      // }
      // else {
      if (_e != null) {
        y = _e.getBoundingClientRect().top + window.scrollY + yOffset;
      }
      else {
        let el = document.getElementById(_id);
        y = el.getBoundingClientRect().top + window.scrollY + yOffset;
      }
      window.scrollTo({ top: y + 10, behavior: 'smooth' }); //igliszki
    }
  }
  // }
  catch (err) {
    // console.log(err);
  }
}

export { navLinkClick, highlightActiveNavLink, scrollOffset };