import { Outlet } from "react-router-dom";
import Menu from "./Menu";
import Footer from "./Footer";

export default function Layout(props) {
    return (
        <div>
            <Menu />
            <main className="bg-mainBgColor main">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
}
