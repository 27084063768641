import { NavLink, useLocation } from "react-router-dom";
import mywork1 from "../assets/images/mywork1.jpg"
import mywork2 from "../assets/images/mywork2.jpg"
import mywork3 from "../assets/images/mywork3.jpg"
import '../assets/styles/mywork.css';
import { navLinkClick, scrollOffset } from '../services/navigation';
import { useContext } from 'react';
import { mainContext } from '../context/mainContext';

export default function MyWorks(props) {
   const { navHeight, setNavActive } = useContext(mainContext);
   const location = useLocation();

   return (
      <>
         <section className="MyWork">
            <div className="transition_top"></div>
            <div className="bg-myWorkBgColor myWorkContainer">
               <h2 className="mb-6">Munkáim</h2>
               <div className="myWorkPicturesContainer myWorkPictures">
                  <img src={mywork1} alt="" className="bg-aboutPicturesBgColor" />
                  <img src={mywork2} alt="" className="bg-aboutPicturesBgColor" />
                  <img src={mywork3} alt="" className="bg-aboutPicturesBgColor" />
               </div>
               <NavLink
                  to="/galeria"
                  className="mt-8"
                  onClick={(e) => [navLinkClick(), setNavActive("galeria"), scrollOffset(e, location, navHeight)]}>
                  <button className="bg-myWorkButtonColor text-center text-myWorkButtonTextColor watch_gallery">Megnézem a galériát</button>
               </NavLink>
            </div>
         </section>
      </>
   );
}