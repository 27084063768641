import React from 'react';
import carousel1 from "../assets/images/carousel1.png";
import carousel2 from "../assets/images/carousel2.jpg";
import carousel3 from "../assets/images/carousel3.jpg";
import '../assets/styles/about.css';
import Carousel from 'react-bootstrap/Carousel';

export default function About(props) {
    return (
        <>
            <section id="rolam" className="aboutContainerBox">
                <div className="img_div ">
                    <Carousel
                        showArrows={true}
                        showThumbs={false}
                        showStatus={false}
                        autoPlay={true}
                        interval={3500}
                        infiniteLoop={true}
                    >
                        <Carousel.Item>
                            <img src={carousel1} alt="Carousel 1" className="aboutPictures1" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={carousel2} alt="Carousel 2" className="aboutPictures" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={carousel3} alt="Carousel 3" className="aboutPictures" />
                        </Carousel.Item>
                    </Carousel>

                </div>

                <div className="bg-aboutTextBgColor aboutTextBox text_div">
                    <h2 className="font-bold text-aboutHeaderTextColor">Üdvözöllek az oldalamon!</h2>
                    <div className="text-aboutTextFontColor">
                        <p>
                            Gerencsér Dóri vagyok, 2012-ben végeztem fodrászként, azóta is a szakmában dolgozom, rengeteg továbbképzésen vettem részt.
                            Igyekszem mindig elsajátítani a legújabb trendeket.
                        </p>
                        <br />
                        <p>
                            Ha ki kellene emelnem valamit mi az erősségem a szakmán belül fodrászként, akkor a hajfestést, hajvágást mondanám.
                            Amire számíthatsz ha eljössz hozzám az a nyugalom és természetesen egy szép haj, ha festésre jössz hozzám akkor egy finom kávéval kínállak.
                        </p>
                        <br />
                        <p>
                            Szeretettel várok minden kedves régi és új vendéget!
                        </p>
                        <br />
                        <p>
                            ”Az élet túl rövid ahhoz, hogy ne szeresd azt, amit csinálsz.” Ray Kroc
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}