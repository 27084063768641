import { useEffect, useContext } from 'react';
import { highlightActiveNavLink } from '../services/navigation';
import { mainContext } from "../context/mainContext.js"
import "../assets/styles/companyinfo.css"

export default function CompanyInfo(props) {
    const { navHeight } = useContext(mainContext);

    useEffect(() => {
        highlightActiveNavLink("");
        window.scrollTo({ top: -navHeight, behavior: 'smooth' });     

        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);
    return (
        <>
            <section id="ceginfo" className="flex flex-col flex-wrap gap-24">
                <div>
                    <h3 className='font-bold text-4xl mb-4 text-aboutHeaderTextColor'>Vállalkozás adatai:</h3>
                    <p className="mb-2 text-xl ">Cégnév: Gerencsér Dóra ev. kisadózó</p>
                    <p className="mb-2 text-xl">Székhely: Székesfehérvár Lugosi utca 7</p>
                    <p className="mb-2 text-xl">Posta cím: Polgárdi Gábor Áron utca 16</p>
                    <p className="mb-2 text-xl">Adószám: 67899687-1-07</p>
                    <p className="mb-2 text-xl">Nyílvántartási szám: 50921739</p>
                    <p className="mb-2 text-xl">Telefonszám: +36308535821</p>
                    <p className="mb-2 text-xl">Bankszámla szám: 10700093-69939174-51100005 Cib Bank</p>
                    <p className="mb-2 text-xl">E-mail: heididori91@gmail.com</p>
                </div>

                <div>
                <h3 className='font-bold text-4xl mb-4'>Tárhely szolgáltató adatai:</h3>
                    <p className="mb-2 text-xl">Cégnév: Netlify, Inc.</p>
                    <p className="mb-2 text-xl">Cím: 44 Montgomery St Suite 300 San Francisco, California 94104 US</p>
                </div>
            </section>
        </>
    );
}