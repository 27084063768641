import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/images/logo.png"
import Scrollspy from 'react-scrollspy'
import { mainContext } from '../context/mainContext';
import { useState, useEffect, useRef, useContext } from 'react';
import { navLinkClick, scrollOffset } from '../services/navigation';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../assets/styles/menu.css';
// import {Nav, Navbar, Container} from 'react-bootstrap';


export default function Menu(props) {
  const { navHeight, setNavHeight, setNavActive } = useContext(mainContext);
  const refNav = useRef(null);
  const location = useLocation();

  const [stickyClass, setStickyClass] = useState('');


  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    setNavHeight(refNav.current.clientHeight);

    // eslint-disable-next-line react-hooks/exhaustive-deps        
  }, []);

  //--Stick navbar to top on scroll down
  const stickNavbar = () => {
    try {
      if (window !== undefined) {
        let windowHeight = window.scrollY;
        windowHeight > 0 ? setStickyClass('navbar_sticky') : setStickyClass('');
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  function floatRightNav() {
    const toggleNav = document.getElementById('floatrightNav');
    toggleNav.classList.add("floatrightNavbar");
  }


  return (

    <Navbar expand="lg" className={`navbar1 ${stickyClass} navbar-dark`} id='navBar' ref={refNav}>
      <Container className={`nav_content`}>
        <NavLink
          to="/"
          className=''
          onClick={() => [navLinkClick(), setNavActive("rolam")]}>
          <img className="navlogo"
            src={logo} alt="Kezdő oldal"></img>
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id='toggleNavBtn' onClick={floatRightNav} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav_middle" id="floatrightNav">
            <Scrollspy
              items={['rolam', 'arlista', 'galeria', 'kapcsolat']}
              currentClassName="active_link"
              offset={-navHeight}>
              <HashLink
                id='rolamLink'
                smooth to='/#rolam'
                className='nav_link'
                scroll={(e) => scrollOffset(e, location, navHeight)}
                onClick={() => [navLinkClick(), setNavActive("rolam")]}>Rólam</HashLink>
              <HashLink
                id='arlistaLink'
                smooth to='/#arlista'
                className='nav_link'
                scroll={(e) => scrollOffset(e, location, navHeight)}
                onClick={() => [navLinkClick(), setNavActive("arlista")]}>Árlista</HashLink>
              <NavLink
                id='galeriaLink'
                to="/galeria"
                className='nav_link galeria_navlink'
                scroll={(e) => scrollOffset(e, location, navHeight)}
                onClick={(e) => [navLinkClick(), setNavActive("galeria"), scrollOffset(e, location, navHeight)]}>Galéria</NavLink>
              <HashLink
                id='kapcsolatLink'
                smooth to={location.pathname.includes("galeria") ? "#kapcsolat" : "/#kapcsolat"}
                className='nav_link'
                scroll={(e) => scrollOffset(e, location, navHeight)}
                onClick={() => [navLinkClick(), setNavActive("kapcsolat")]}>Kapcsolat</HashLink>
            </Scrollspy>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}