import logo from "../assets/images/logo.png"
import '../assets/styles/footer.css';
import { HiPhone } from 'react-icons/hi';
import { AiOutlineMail } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { IoMdContact } from "react-icons/io";
import { FaTiktok } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { BsFillGeoAltFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useContext } from 'react';
import { mainContext } from "../context/mainContext.js"

export default function Footer(props) {
    const { navHeight } = useContext(mainContext);

    return (
        <>
            <section id="kapcsolat">
                <div className="bg-footerBgColor text-footerFontColor h-100 pt-2 pb-6">

                    <div className="footer_div">

                        <div className="footer_contact">
                            <h2 className="text-3xl mb-4"><IoMdContact />&nbsp;Elérhetőségeim</h2>
                            <p className="text-xl mb-4" ><HiPhone />: +36 30 853 5821 </p>
                            <p className="text-xl mb-4"> <AiOutlineMail />: heididori91@gmail.com </p>
                            <a className="text-xl mb-4 link_underline" href="https://www.facebook.com/profile.php?id=100095170980569&sfnsn=mo" target="_blank" rel="noopener noreferrer"><AiFillFacebook />: Gerencsér Dóri fodrász</a>
                            <a className="text-xl link_underline" href="https://www.tiktok.com/@heididorihairstylist?lang=hu-HU" target="_blank" rel="noopener noreferrer"><FaTiktok />: heididorihairstylist</a>
                        </div>
                        <div className="footer_contact">
                            <h2 className="text-3xl mb-4"><FaClock />&nbsp;Nyitvatartás</h2>
                            <p className="text-xl mb-4">Időpont egyeztetés alapján!</p>

                        </div>
                        <div className="footer_contact">
                            <h2 className="text-3xl mb-4"><BsFillGeoAltFill />&nbsp;Hol találsz meg?</h2>
                            <p className="text-xl mb-4">8000 Székesfehérvár, Balatoni út 103. </p>
                            <iframe className="mapouter" title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5425.316782945493!2d18.40280527459177!3d47.16454455098604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4769f716493cde43%3A0xe1e6daf4a6ceae5f!2zU3rDqWtlc2ZlaMOpcnbDoXIsIEJhbGF0b25pIMO6dCAxMDMsIDgwMDA!5e0!3m2!1shu!2shu!4v1711405804938!5m2!1shu!2shu" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        <div>
                            <div className="footer_image_div text-center">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    className="footer_image mb-4"

                                // CSS CLASS TO BE WRITTEN
                                />
                                <p className="copyright">© Minden jog fenntartva | <NavLink
                                    to="/impresszum"
                                    className="text-base link_underline"
                                    onClick={() => window.scrollTo({ top: -navHeight, behavior: 'smooth' })}
                                >
                                    Impresszum
                                </NavLink></p>

                            </div>


                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}