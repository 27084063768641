// Tools
import { createBrowserRouter } from "react-router-dom"

// Components
import Layout from "../components/Layout.js"
import Home from "../components/Home.js"
import Gallery from "../components/Gallery.js"
import CompanyInfo from "../components/CompanyInfo.js"

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children:[
            {
                path: "/",
                element: <Home/>
            },
            {
                path: "/galeria",
                element: <Gallery />
            },
            {
                path: "/impresszum",
                element: <CompanyInfo/>
            }
        ]
    }
])