import GDImageViewer from "./GDImageViewer.js";
import { useEffect, useContext } from 'react';
import { highlightActiveNavLink, scrollOffset } from '../services/navigation';
import { useLocation } from "react-router-dom";
import { mainContext } from "../context/mainContext.js"
import { useIsMounted } from "../hooks/useIsMounted.js";

export default function Gallery(props) {
    const { navHeight, navActive, setNavActive } = useContext(mainContext);
    const location = useLocation();
    const isMounted = useIsMounted();

    useEffect(() => {
        let active = location.hash; //get section from link, for example: #kapcsolat
        if (active === "" ? active = "galeria" : active = active.slice(1)); //set default or remove '#' from active                                 
        if (navActive === "") {
            setNavActive(active);
        }
        highlightActiveNavLink(navActive);
        scrollOffset(null, location, navHeight, navActive);

        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [isMounted]);

    const data = {
        gkey: process.env.REACT_APP_GD_API_KEY,
        dirId: process.env.REACT_APP_GD_GALLERY_DIR_ID,

        name: "name1",
        options:
        {
            // react style object
            // https://reactjs.org/docs/dom-elements.html#style

            style: {
            },

            // behavior when image is clicked
            // if on click is empty (no modal or newWindow)
            // current tab will show full image
            // if modal true, image opens as overlay
            // on current tab
            // if new window is true, new tab is launched
            // with image url

            onClick: {
                modal: true,
                newWindow: false
            },

            //Use name of <FILE_NAME>.<EXTENSION> (image.png) to 
            // specify which images not to render

            exclude: {
                "1.jpg": true
            },


            //Use name of <FILE_NAME>.<EXTENSION> (image.png) to
            //attach className attribute to a specific image

            attachClass: {
                "2.jpg": "test"
            },


            //Use name of <FILE_NAME>.<EXTENSION> (image.png) to
            //attach id attribute to a specific image

            attachId: {
                "2.jpg": "test2"
            },

            // if set true, hover over opacity effect
            // will be set
            hover: true
        }
    }


    return (
        <>
            <section id="galeria">
                <div className="bg-myWorkBgColor">
                    <GDImageViewer data={data}/>
                </div>
            </section>
        </>
    );

}