import About from "./About.js";
import MyWorks from "./MyWorks.js";
import PriceList from "./PriceList.js";
import { mainContext } from "../context/mainContext.js"
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollOffset, highlightActiveNavLink } from "../services/navigation.js";
import { useIsMounted } from "../hooks/useIsMounted.js";

export default function Home(props) {
   const { navHeight, navActive, setNavActive } = useContext(mainContext);    
   const location = useLocation();    
   const isMounted = useIsMounted();

   useEffect(() => {             
      let active = location.hash; //get section from link, for example: #arlista
      if (active === "" ? active = "rolam" : active = active.slice(1)); //set default or remove '#' from active                                 
      if (navActive === "") {
        setNavActive(active);
      } 
      highlightActiveNavLink(navActive);
      scrollOffset(null, location, navHeight, navActive);

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

    return (
     <div>
        <About/>
        <MyWorks />
        <PriceList />
     </div>
    );
  }