import React, { useEffect, useState } from "react";
import loading from "../assets/images/loading.gif"
import "../assets/styles/GDImageViewer.css";

function GDImageViewer({ data }) {
  const [imgIds, setImgIds] = useState([]);
  const [style, setStyle] = useState({});
  const [hover, setHover] = useState(false);
  const [newWindow, setNewWindow] = useState(false);
  const [clickable, setClickable] = useState(false);
  const [modal, setModal] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [classNames, setClassNames] = useState({});
  const [ids, setIds] = useState({});
  const [excludes, setExcludes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalSrc, setModalSrc] = useState('');

  const GOOGLE_API_KEY = data.gkey;
  const GOOGLE_DRIVE_URL_START = "https://www.googleapis.com/drive/v2/files?q=%27";
  const GOOGLE_DRIVE_URL_END = "%27+in+parents&key=";
  const GOOGLE_DRIVE_IMG_URL = "https://drive.google.com/thumbnail?id=";

  const options = data.options;
  const header = data.header;

  useEffect(() => {
    loadData();
    loadSettings(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadSettings(options) {
    if (options.style) {
      setStyle(options.style);
    }
    if (options.onClick) {
      setClickable(true);
      if (options.onClick.newWindow) {
        setNewWindow(true);
      }
      if (options.onClick.modal) {
        setModal(true);
      }
    }
    if (options.hover) {
      setHover(true);
    }
    if (header) {
      setShowHeader(true);
    }
    if (options.attachClass) {
      setClassNames(options.attachClass);
    }
    if (options.attachId) {
      setIds(options.attachId);
    }
    if (options.exclude) {
      setExcludes(options.exclude);
    }
  }

  async function loadData() {
    try {
      const response = await fetch(
        `${GOOGLE_DRIVE_URL_START}${data.dirId}${GOOGLE_DRIVE_URL_END}${GOOGLE_API_KEY}`
      );
      const jsonResp = await response.json();
      setImgIds(jsonResp.items);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  function checkFormat(url) {
    return /\.(jpeg|jpg|gif|png)$/.test(url);
  }

  function showModal(imgSrc) {
    setModalSrc(imgSrc);
    const modal = document.getElementById("modal-container");
    modal.style.display = "flex";
  }

  window.onclick = function (event) {
    const modal2 = document.getElementById("modal-container");
    if (event.target === modal2) {
      modal2.style.display = "none";
    }
  }

  function hideModal() {
    const modal = document.getElementById("modal-container");
    modal.style.display = "none";
    setModalSrc('');
  }

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const debouncedShowModal = debounce(showModal, 300);

  function handleImageClick(item) {
    if (modal) {
      showModal(`${GOOGLE_DRIVE_IMG_URL}${item.id}&sz=w800`);
    } else if (clickable && !modal) {
      const href = `${GOOGLE_DRIVE_IMG_URL}${item.id}`;
      if (newWindow) {
        window.open(href, "_blank");
      } else {
        window.location.href = href;
      }
    } else {
      debouncedShowModal(`${GOOGLE_DRIVE_IMG_URL}${item.id}&sz=w800`);
    }
  }

  const renderImages = (className, id, exclude, item, i) => (
    <div key={i} className="">
      {!exclude && (
        <img
          style={style}
          className={`gd-img ${clickable ? "gd-pointer" : ""} ${hover ? "gd-hover" : ""} ${className} shadow-picturesBoxShadowColor rounded-xl h-96 m-10`}
          onClick={() => handleImageClick(item)}
          src={`${GOOGLE_DRIVE_IMG_URL}${item.id}&sz=w500`}
          id={id || null}
          alt={item.title}
        />
      )}
    </div>
  );

  const renderMain = (className, id, exclude, href, target, item, i) => {
    if (href) {
      return (
        <a key={i} href={href} target={target}>
          {renderImages(className, id, exclude, item, i)}
        </a>
      );
    }
    return renderImages(className, id, exclude, item, i);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center vh-100">
        <img src={loading} alt="Képek betöltése..." />
      </div>
    )
  }

  return (
    <div>
      <h2>{showHeader && header}</h2>
      {modal && (
        <div id="modal-container" className="modal">
          <span className="close" onClick={hideModal} >&times;</span>
          <img className="modal-content" id="curr-modal" alt="modal" src={modalSrc} />
        </div>
      )}
      <div className="flex flex-wrap space-evenly mx-auto justify-center">
        {imgIds?.map ? imgIds.map((item, i) => {
          const title = item.title || "";
          if (checkFormat(title)) {
            const className = classNames[title] || "";
            const id = ids[title] || "";
            const exclude = excludes[title] || false;
            const href = !modal && clickable ? `${GOOGLE_DRIVE_IMG_URL}${item.id}` : "";
            const target = newWindow ? "_blank" : "";
            return renderMain(className, id, exclude, href, target, item, i);
          }
          return null;
        }
        )
          : <div className="flex justify-center items-center vh-100">
            <img src={loading} alt="Képek betöltése..." />
          </div>
        }
      </div>
    </div>
  );
}

export default GDImageViewer;
