import { RouterProvider } from "react-router-dom";
import { router } from "./services/path";
import { mainContext } from "./context/mainContext.js";
import { useState } from 'react';

function App() {
  const [navHeight, setNavHeight] = useState(0); //Store navbar height for offset
  const [navActive, setNavActive] = useState(""); //Store active navlink ID

  return (
    <div className="App bg-mainBgColor">
      <mainContext.Provider
        value={{
          navHeight, setNavHeight,
          navActive, setNavActive
        }}
      >
        <RouterProvider router={router} />
      </mainContext.Provider>
    </div >
  );
}

export default App;
